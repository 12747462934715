
import { defineComponent } from "vue";
import { Store } from "vuex";

import NewResultFormComp from "@/components/semafor/NewResultFormComp.vue";
import ResultPost from "@/types/results-post.model";
import ResultsListComp from "@/components/semafor/ResultsListComp.vue";
import SemaforComp from "@/components/semafor/SemaforComp.vue";

export default defineComponent({
  name: "Semafor",
  components: {
    NewResultFormComp,
    SemaforComp,
    ResultsListComp,
  },
  computed: {
    isFinished(): Store<ResultPost> {
      return this.$store.state.isFinished;
    },
    windowWidth(): Store<ResultPost> {
      return this.$store.state.windowWidth;
    },
  },
  data() {
    return {
      submitFormDialog: false,
      fill: true,
    };
  },
  mounted() {
    this.onResize();
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
  },
  methods: {
    onResize() {
      this.$store.dispatch("getWindowWidth", window.innerWidth);
    },
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.onResize);
  },
});
