
import CryptoJS from "crypto-js";
import { defineComponent } from "vue";
import MD5 from "crypto-js/md5";
import firebase from "firebase/app";
import "firebase/auth";

import ResultsForm from "../../types/results-form";
import PostForm from "../../types/post-form";
import { Store } from "vuex";
import ResultPost from "@/types/results-post.model";
import { Device } from "@capacitor/device";

let formData: ResultsForm = {
  age: 6,
  email: "",
  name: "",
  sex: "",
};

let isMobile: boolean;

export default defineComponent({
  name: "NewResultFormComp",
  computed: {
    isButtonDisabled(): Store<ResultPost> {
      const store = this.$store.state.results;
      return (
        store && store[isMobile ? "mobile" : "desktop"]?.rounds?.length === 5
      );
    },
  },
  data() {
    return {
      formData,
      errors: [] as string[],
      dropDownSex: [
        {
          value: "male",
          label: "Muž",
        },
        {
          value: "female",
          label: "Žena",
        },
        {
          value: "other",
          label: "Ostatní",
        },
      ],
      size: 24,
    };
  },
  async mounted() {
    const info = await Device.getInfo();
    isMobile =
      info.operatingSystem === "ios" || info.operatingSystem === "android";
  },
  methods: {
    onSubmitForm() {
      this.errors = [];

      Number(this.formData.age) < 6
        ? (this.errors[0] = "Minimální věk 6 let!")
        : this.errors.slice(0, 0);

      Number(this.formData.age) > 100
        ? (this.errors[0] = "Maximální věk 99 let!")
        : this.errors.slice(0, 0);

      this.formData.name
        ? this.errors.slice(0, 1)
        : (this.errors[1] = "Jméno je povinné!");
      this.formData.sex
        ? this.errors.slice(0, 2)
        : (this.errors[2] = "Pohlaví je povinné!");
      this.formData.email
        ? this.errors.slice(0, 3)
        : (this.errors[3] = "Email je povinný!");

      if (this.errors.length === 0) {
        this.submitData();
      }
    },
    hashData() {
      const hash = CryptoJS.HmacSHA512(
        `${
          this.formData.age
        }+${this.formData.name.toLowerCase()}+${this.formData.email.toLowerCase()}`,
        this.formData.email.toLowerCase()
      );
      return MD5(hash).toString();
    },
    async submitData() {
      const results: PostForm = {
        age: Number(this.formData.age),
        sex: this.formData.sex,
        hash: this.hashData(),
      };

      await this.$store.dispatch("saveSemaforResults", {
        id: results.hash,
        results,
      });

      await this.$store
        .dispatch("postResults")
        .then(() => {
          alert("Záznam uložen v pořádku");
        })
        .catch((error) => {
          alert(error);
        });

      await this.$store.dispatch("clearStore");

      firebase.auth().signOut();
      return;
    },
  },
});
