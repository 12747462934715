
import { defineComponent } from "vue";
import { Device } from "@capacitor/device";
import { Store } from "vuex";

import ResultPost from "@/types/results-post.model";

let isMobile: boolean;

export default defineComponent({
  name: "ResultsListComp",
  async mounted() {
    const info = await Device.getInfo();
    isMobile =
      info.operatingSystem === "ios" || info.operatingSystem === "android";
  },
  data() {
    return {
      size: 8,
      clicked: false,
    };
  },
  computed: {
    getResultsStore(): Store<ResultPost> {
      const store = this.$store.state.results;
      return store && store[isMobile ? "mobile" : "desktop"];
    },
    windowWidth(): Store<ResultPost> {
      return this.$store.state.windowWidth;
    },
    getCompareMessage(): Store<ResultPost> {
      return this.$store.state.compareMesssage;
    },
  },
  methods: {
    compareResults() {
      this.clicked = true;
      this.$store.dispatch("getDocsById", {
        commitName: "findClosest",
        docId: ["values-rounded-corrected"],
      });

      setTimeout(() => {
        this.clicked = false;
      }, 3000);
    },
  },
});
