
import { defineComponent } from "vue";
import firebase from "firebase/app";
import "firebase/auth";

import router from "./router";

export default defineComponent({
  computed: {
    isLogged() {
      return this.$store.getters.isLogged;
    },
  },
  data() {
    return {
      environment: "",
    };
  },
  mounted() {
    this.environment = process.env.NODE_ENV.substring(0, 3);
    document.title = process.env.VUE_APP_TITLE;
  },
  methods: {
    goToAdmin() {
      router.push("admin");
    },
    goToHome() {
      router.push("/");
    },
    logout() {
      firebase.auth().signOut();
      this.$store.commit("isLogged", false);
      router.push("/login");
    },
  },
});
