import { toDisplayString as _toDisplayString, createVNode as _createVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, Fragment as _Fragment } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_space = _resolveComponent("el-space")!
  const _component_el_header = _resolveComponent("el-header")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode(_component_el_header, null, {
      default: _withCtx(() => [
        _createVNode(_component_el_space, {
          wrap: "",
          size: "large",
          class: "header-custom"
        }, {
          default: _withCtx(() => [
            _createVNode("h6", null, "v1.5.6-" + _toDisplayString(_ctx.environment), 1),
            _createVNode(_component_el_space, null, {
              default: _withCtx(() => [
                _createVNode(_component_el_button, {
                  size: "mini",
                  icon: "el-icon-s-home",
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.goToHome()))
                }),
                _createVNode(_component_el_button, {
                  size: "mini",
                  icon: "el-icon-setting",
                  onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.goToAdmin()))
                }),
                (_ctx.isLogged)
                  ? (_openBlock(), _createBlock(_component_el_button, {
                      key: 0,
                      size: "mini",
                      icon: "el-icon-switch-button",
                      type: "danger",
                      onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.logout()))
                    }))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_router_view)
  ], 64))
}