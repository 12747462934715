
import { defineComponent } from "vue";
import { Store } from "vuex";
import { Device } from "@capacitor/device";

import SemaforRound from "../../types/results-round";
import ResultPost from "@/types/results-post.model";

const fill = true;
const size = 32;
let rounded: string | undefined = undefined;
let roundedCorrected: string | undefined = undefined;
let results: SemaforRound[] = [];
let isMobile: boolean;
let clicksCount: number;

export default defineComponent({
  name: "SemaforComp",
  async mounted() {
    const info = await Device.getInfo();
    isMobile =
      info.operatingSystem === "ios" || info.operatingSystem === "android";
    this.$store.dispatch("saveSemaforResults", { isMobile });
    window.addEventListener("keydown", (e) => {
      if (e.code === "Space") {
        this.checkCounts();
      }

      if (
        e.code === "Space" &&
        this.round > 0 &&
        this.round < 6 &&
        this.greenActive
      ) {
        this.onSaveResult();
      }
    });
  },
  computed: {
    windowWidth(): Store<ResultPost> {
      return this.$store.state.windowWidth;
    },
  },
  data() {
    return {
      redActive: false,
      greenActive: false,
      minTime: 2000,
      maxTime: 8000,
      startTime: 0,
      round: 0,
      isStarted: false,
      isFinished: false,
      fill,
      size,
      aboutTestDialog: false,
      clicksCount,
      maxCountDialog: false,
      showClose: false,
    };
  },
  methods: {
    onTestStart() {
      this.isStarted = true;
      this.round = 0;
      results = [];
      this.$store.dispatch("clearStore");
      this.clicksCount = 10;
      this.startTimer();
    },
    startTimer() {
      const timeout =
        Math.random() * (this.maxTime - this.minTime) + this.minTime;
      this.startTime = 0;
      this.round = this.round + 1;
      this.redActive = !this.redActive;

      setTimeout(() => {
        this.redActive = !this.redActive;
        this.greenActive = !this.greenActive;
        this.startTime = Date.now();
      }, timeout);
    },
    onButtonClick() {
      this.checkCounts();
      if (this.greenActive) {
        this.onSaveResult();
      }
    },
    checkCounts() {
      if (this.clicksCount <= 1) {
        this.maxCountDialog = true;
      } else {
        this.clicksCount--;
      }
    },
    onSaveResult() {
      const clickTime = Date.now();
      const result = clickTime - this.startTime;
      let median: string;

      results.push({ value: result, round: this.round });
      rounded = this.calculateRounded().toFixed(2);
      roundedCorrected = this.calculateRoundedCorrected().toFixed(2);
      median = this.calculateMedian();
      this.greenActive = !this.greenActive;

      const payload = {
        results: {
          [isMobile ? "mobile" : "desktop"]: {
            rounds: results,
            roundedValue: rounded,
            roundedValueCorrected: roundedCorrected,
            median,
          },
        },
      };
      this.$store.dispatch("saveSemaforResults", payload);

      if (this.round < 5) {
        this.startTimer();
      } else {
        this.isStarted = false;
        this.isFinished = true;
        this.$store.dispatch("saveSemaforResults", { isFinished: true });
      }
    },
    calculateRounded() {
      let number = 0;
      for (const result of results) {
        if (result.value) {
          number = number + result.value;
        }
      }

      return number / results.length;
    },
    calculateRoundedCorrected() {
      function compareNumbers(a: string, b: string) {
        return Number(a) - Number(b);
      }

      const sorted = results
        .map((item: SemaforRound) => String(item.value))
        .sort(compareNumbers);

      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const first = sorted.shift();
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const last = sorted.pop();

      let number = 0;
      for (const result of sorted) {
        if (result) {
          number = number + Number(result);
        }
      }

      return number / sorted.length;
    },
    calculateMedian(): string {
      const resultsMap = results.map((result) => result.value).sort();
      return String(resultsMap[2]);
    },
    reloadPage() {
      location.reload();
      return false;
    },
  },
});
